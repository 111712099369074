import styledEmotion, {
  injectGlobal as injectGlobalEmotion,
  css as cssEmotion,
  cx as cxEmotion,
  keyframes as keyframesEmotion,
} from 'react-emotion';

// we use the utils/emotion file as single source of truth transition file to make future updates easier
export const css = cssEmotion;
export const cx = cxEmotion;
export const injectGlobal = injectGlobalEmotion;
export const keyframes = keyframesEmotion;
export const styled = styledEmotion;
