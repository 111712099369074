import { injectGlobal } from 'utils/emotion';
import themeDefault from './themeDefault';

/* eslint no-unused-expressions: 0 */
injectGlobal`
  html {
    text-rendering: optimizeLegibility;
    overflow-x: hidden;
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    background-color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
    font-family: ${themeDefault.font.primaryFamily}, sans-serif;
    font-size: 16px;
    color: ${themeDefault.textColor};
  }
  html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    min-width: ${themeDefault.grid.pageMinWidth};
  }

  body {
    line-height: 1.7;
  }

  *,
  *:after,
  *:before {
    box-sizing: inherit;
    margin: 0;
    padding: 0;
  }
  ul {
    list-style-position: inside;
  }
  input,
  label,
  select,
  button,
  textarea {
  	margin:0;
  	border:0;
  	padding:0;
  	display:inline-block;
  	vertical-align:middle;
  	white-space:normal;
  	background:none;
    line-height:1;
    font-family: ${themeDefault.font.primaryFamily}, sans-serif;
  }

  /* Remove the outer glow in webkit */
  input:focus {
  	outline:0;
  }

  [role="button"],
  input[type="submit"],
  input[type="reset"],
  input[type="button"],
  button {
    -webkit-box-sizing: content-box;
       -moz-box-sizing: content-box;
            box-sizing: content-box;
  }

  input[type="submit"],
  input[type="reset"],
  input[type="button"],
  button {
    cursor: pointer;
    background: none;
    border: 0;
    color: inherit;
    /* cursor: default; */
    font: inherit;
    line-height: normal;
    overflow: visible;
    padding: 0;
    -webkit-appearance: button; /* for input */
    -webkit-user-select: none; /* for button */
       -moz-user-select: none;
        -ms-user-select: none;
  }

  input::-moz-focus-inner,
  button::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  button:hover, button:focus {
    color: inherit;
  }

  a {
    color: ${themeDefault.linkColor};
    font-weight: 600;
    text-decoration: none;
  }

  a:hover,
  a:active,
  a:focus {
    color: ${themeDefault.linkColorHover};
    text-decoration: underline;
  }

  a:hover svg, a:focus svg,
  button:hover svg, button:focus svg {
    opacity: 1;
  }

  .no-scroll {
    overflow:hidden;
  }

  strong {
    font-weight: 600;
  }
`;
