import React from 'react';
import scrollToElement from 'scroll-to-element';
import Link from 'components/Link';

import { css } from 'emotion';
import styled from 'react-emotion';

import media from 'utils/mediaqueries';

import IconArrowUp from 'components/Svg/IconArrowUp';

// Legal
const LegalRow = styled('div')({
  display: 'block',
  textAlign: 'center',
  marginBottom: 200, // we need to account for the cookie information

  [media('sm')]: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxSizing: 'border-box',
    textAlign: 'initial',
    marginBottom: 130, // we need to account for the cookie information
  },

  [media('md')]: {
    marginBottom: 80, // we need to account for the cookie information
  },
});

const ScrollToTopButton = styled('button')({
  color: 'inherit',
  background: 'transparent',
  border: 0,
  marginBottom: 20,

  '&:active, &:focus': {
    outline: 0,
    opacity: 0.5,
  },

  [media('sm')]: {
    display: 'inline-flex',
    alignItems: 'center',
    flex: 'none',
    marginBottom: 0,
    marginRight: 40,
  },

  svg: {
    marginLeft: 10,
    fontSize: 10,

    [media('sm')]: {
      marginLeft: 15,
    },
  },
});

const NavStyled = styled('div')({
  display: 'block',

  [media('sm')]: {
    display: 'inline-block',
    textAlign: 'right',
    marginBottom: 0,
  },
});

const itemStyles = css({
  display: 'inline-block',
  fontWeight: 400,
  color: 'inherit',
  padding: 10,
});

const ItemStyled = styled('span')(itemStyles);
const ItemLinkStyled = styled(Link)(itemStyles, {
  '&:hover, &:active, &:focus': {
    color: 'inherit',
    textDecoration: 'underline',
  },
});

const FooterLegal = ({ copyrightText, navigationItems }) => {
  // Get year dynamically.
  const today = new Date();
  const currentYear = today.getFullYear();

  return (
    <LegalRow>
      <ScrollToTopButton
        onClick={() => {
          scrollToElement('#header', {
            align: 'top',
            duration: 300,
            offset: -50,
          });
        }}
      >
        Nach oben <IconArrowUp />
      </ScrollToTopButton>
      <div>
        <NavStyled>
          {navigationItems.map(item => (
            <ItemLinkStyled key={`meta-link-${item.id}`} to={item.fullUri}>
              {item.title}
            </ItemLinkStyled>
          ))}
          <ItemStyled>
            © {currentYear} {copyrightText}
          </ItemStyled>
        </NavStyled>
      </div>
    </LegalRow>
  );
};

export default FooterLegal;
