/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import PropTypes from 'prop-types';
import { Link as GatsbyLink } from 'gatsby';
// import { OutboundLink } from 'gatsby-plugin-google-analytics';

/**
 * Function:
 * Adjust backend url.
 *
 * @param string url
 */
function adjustBackendUrl(url) {
  // Skip adjustment if cms url isn't included.
  // console.log({ url });
  if (!url || url.indexOf(process.env.GATSBY_CMS_URL) === -1) {
    return url;
  }

  // Check if the link points to a file.
  const filename = url.split('/').pop();
  const isFile = /.+\..+/.test(filename);

  if (isFile) {
    return url;
  }

  // If it's a normal cms url, remove the cms link, so it's seen as internal link.
  return url.replace(process.env.GATSBY_CMS_URL, '');
}

export default function Link({
  children,
  target,
  className,
  activeClassName,
  rel,
  to,
  onClick,
}) {
  const hasClickEvent = typeof onClick === 'function';

  // Warn if nothing would happen and ignore the link.
  if (!to && !hasClickEvent) {
    console.warn('Invalid target for a link on this page. Children:', children);
    return <span>{children}</span>;
  }
  // console.log({ to });
  // Adjust urls from backend.
  const toStripped = adjustBackendUrl(to);

  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(toStripped);

  // console.log({to,toStripped,internal})

  // Use Gatsby <Link> for internal links.
  if (!internal || target === '_blank') {
    return (
      // Use <a> for internal links that open in a new tab.
      <a
        href={to}
        className={className}
        target="_blank"
        rel={rel}
        onClick={onClick}
      >
        {children}
      </a>
    );
  }

  return (
    // Use Gatsby <Link> for internal links.
    <GatsbyLink
      to={toStripped}
      className={className}
      activeClassName={activeClassName}
      target={target}
      rel={rel}
      onClick={onClick}
    >
      {children}
    </GatsbyLink>
  );

  // Use <OutboundLink> for external links.
  // return (
  //   <GatsbyLink
  //     href={toStripped}
  //     target={target}
  //     rel="noopener noreferrer"
  //     className={className}
  //     onClick={onClick}
  //   >
  //     {children}
  //   </GatsbyLink>
  // );
}

Link.propTypes = {
  target: PropTypes.string,
};

Link.defaultProps = {
  target: null,
};
