import React from 'react';
import { styled } from 'utils/emotion';

const SvgStyled = styled('svg')({
  '.letter': {
    fill: '#1d1d1b',
    opacity: 1,
  },

  '.square': {
    fill: '#0069e7',
    opacity: 1,
  },

  '.square--secondary': {
    fill: '#002aa0',
    opacity: 1,
  },
});

export default function Logo({ themeName, className, ...rest }) {
  return (
    <SvgStyled
      className={`logo ${className}`}
      viewBox="0 0 300 99"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...rest}
    >
      <g>
        <path
          className="square"
          d="M94.38,26.41c-4,.1-7.95.18-11.95.27-.2,5.19-.3,10.38-.38,15.57-.2,14.21-.18,28.42-.16,42.62l1.27-.11A47,47,0,0,1,95,85.25a4,4,0,0,1,1.6.53c.42.28.73.72,1.17,1,1.09.61,2.45-.14,3.69,0l.09,0a28.8,28.8,0,0,1-.34-3.35c-.21-3.37-.41-6.74-.62-10.11q-.23-3.66-.46-7.32a51.42,51.42,0,0,1-.11-6.91,38.28,38.28,0,0,0,.4-6.31c-.08-.92-.28-1.82-.4-2.73a32.71,32.71,0,0,1-.17-4.2c0-5,.8-10.07.06-15l-.72-4.71A5.47,5.47,0,0,1,94.38,26.41Z"
        />
        <path
          className="square"
          d="M5.55,77.9l11.9.3,5.95.15-.27-13.66c-.05-2.52-.1-5-.19-7.56-.37-10.71-.72-21.41-.47-32.13,0-.34,0-.69,0-1l-9.18-1a15.58,15.58,0,0,0-5.9.13c-.1,5.82-.18,11-.23,17.22-.35-.83-1.31-1.82-1.94-1.18a1.42,1.42,0,0,0-.26,1q.25,12.08.52,24.16c0,1.27.44,2.92.41,4.18,0,1.89-.09,3.78-.13,5.67a5,5,0,0,1-.34,2,3.08,3.08,0,0,1-1.39,1.7,1.9,1.9,0,0,0-.9.74l.19-.19A3.08,3.08,0,0,1,5.55,77.9Z"
        />
        <path
          className="square"
          d="M8.58,11.19c.16-.39.87.08,1.07.44a2.29,2.29,0,0,1,.12,1.22A20.15,20.15,0,0,0,10,18c.05.48.16,1,.59,1.26a.52.52,0,0,0,.3.06c.15,0,.38-.21.38-.36s0-.66,0-1.08c-.28-2.78-.68-2.87-.67-5.67,0-.43.13-1.56.17-2,0-.58.15-1.28.69-1.52a3,3,0,0,1,1.23,0c1.63,0,3-2.17,4.47-1.47.42.21.74.63,1.21.67,1,.08,1.52-1.6,2.5-1.39.6.12.79.88.86,1.49a121.92,121.92,0,0,1,.79,16l4.54.5c11.14,1.21,22.55,1.35,33.72,2.12,2.31.16,4.62.32,6.93.36,2,0,4,0,6-.07l8.72-.2c.2-5.2.49-10.39.93-15.57a10.39,10.39,0,0,1,.51-2.83,1.1,1.1,0,0,1,.47-.64c.74-.38,1.26.81,1.26,1.65.31-.45,1,0,1.25.47s.38,1.14.9,1.27c.28.07.6,0,.86.1.47.26.24,1,.45,1.49.46,1.09,2.32.27,3.2,1a4.53,4.53,0,0,1,.65,1c.29.43.75.74,1.07,1.15,1.32,1.7.46,4.33,1.43,6.25,0,.09.1.18.2.19s.21-.19.23-.34c.15-2-.15-4,.47-5.93.12-.38.36-.82.75-.77s.51.5.56.87q.76,5,1.52,10.08a6.56,6.56,0,0,0,1.45-1,13.81,13.81,0,0,0,3.49-5.64,5.75,5.75,0,0,0,.45-4c-.17-.46-.46-.93-.32-1.4.22-.73,1.39-1,1.26-1.8-.1-.6-1-.73-1.28-1.27-.44-.81.68-1.57,1-2.44a2.08,2.08,0,0,0-1.14-2.41,6,6,0,0,0-2.86-.44C91.59,5.9,82,6.88,72.37,6.5,67.1,6.3,61.86,5.68,56.6,5.32A167.62,167.62,0,0,0,39,5c-6.76.25-13.65.9-20.19-.84C16,3.46,13.37,2.31,10.55,2.06a25.8,25.8,0,0,0-5.48.27,1.26,1.26,0,0,0-.66.21,1.25,1.25,0,0,0-.35.65A45,45,0,0,0,2.86,22.3a2.32,2.32,0,0,0,.62,1.49c.83.71,2.06.05,3.06-.38a5.29,5.29,0,0,1,.87-.31c0-1.34,0-2.71.08-4.13A22.12,22.12,0,0,1,8.58,11.19Z"
        />
        <path
          className="square"
          d="M80.75,95.67a1.82,1.82,0,0,1,0-1.62c.17-.51.31-1,.44-1.58a56.71,56.71,0,0,0,.68-7.11v-.49a8.65,8.65,0,0,1-5.08-.7,4.68,4.68,0,0,0-1.24-.63,3.8,3.8,0,0,0-1.34.07c-5.49.87-10.92-2.2-16.48-2-1.67.08-3.4.44-5-.15-.8-.3-1.49-.84-2.26-1.22a13.16,13.16,0,0,0-5.29-1.06c-7.27-.43-14.56-.66-21.84-.86q.1,6,.23,11.92a1.71,1.71,0,0,1-.1.75,1.45,1.45,0,0,1-.67.6,9.16,9.16,0,0,1-4.68.7,82.3,82.3,0,0,1-13.68-2c-.94,0-1.41-.13-1.78-.72a3.38,3.38,0,0,1-.43-1.16l0,.46c.09,1.52-.76,5.2.71,6.13a4.61,4.61,0,0,0,2.24.54L26,97a105.62,105.62,0,0,0,12.45.38c3.71-.18,7.41-.76,11.12-.61,2.48.09,4.93.51,7.4.68a70.51,70.51,0,0,0,8.6,0C72.63,97,79.79,97.14,86,97a13.45,13.45,0,0,1-4.77-1A1.47,1.47,0,0,1,80.75,95.67Z"
        />
        <path
          className="square square--secondary"
          d="M97.62,16.08c-.05-.37-.19-.82-.56-.87s-.63.39-.75.77c-.62,1.93-.32,3.91-.47,5.93,0,.15-.08.35-.23.34s-.16-.1-.2-.19c-1-1.92-.11-4.55-1.43-6.25-.32-.41-.78-.72-1.07-1.15a4.53,4.53,0,0,0-.65-1c-.88-.77-2.74.05-3.2-1-.21-.49,0-1.23-.45-1.49-.26-.13-.58,0-.86-.1-.52-.13-.68-.78-.9-1.27s-.94-.92-1.25-.47c0-.84-.52-2-1.26-1.65a1.1,1.1,0,0,0-.47.64,10.39,10.39,0,0,0-.51,2.83c-.44,5.18-.73,10.37-.93,15.57,4-.09,8-.17,11.95-.27a5.47,5.47,0,0,0,4.76-.25Q98.39,21.12,97.62,16.08Z"
        />
        <path
          className="square square--secondary"
          d="M5.55,77.9a3.08,3.08,0,0,0-2.21.58l-.19.19a7.4,7.4,0,0,0-.85,3.49c0,.39,0,.79,0,1.18,0,1.33,0,2.7,0,3.85,0,.4,0,.8,0,1.2a3.36,3.36,0,0,0,.42,1.16c.37.59.84.68,1.78.72a82.46,82.46,0,0,0,13.68,2,9.21,9.21,0,0,0,4.69-.7,1.43,1.43,0,0,0,.66-.6,1.58,1.58,0,0,0,.1-.75q-.12-6-.23-11.92l-5.95-.15Z"
        />
        <path
          className="square square--secondary"
          d="M20.86,6.47c-1-.21-1.51,1.47-2.51,1.39-.46,0-.79-.46-1.21-.67-1.46-.7-2.84,1.46-4.46,1.47a3,3,0,0,0-1.23,0c-.54.24-.65.94-.7,1.52,0,.43-.16,1.56-.16,2,0,2.8.38,2.89.66,5.67A9.62,9.62,0,0,1,11.31,19c0,.15-.24.35-.38.36a.52.52,0,0,1-.3-.06c-.43-.22-.54-.78-.6-1.26a20.61,20.61,0,0,1-.24-5.14,2.2,2.2,0,0,0-.13-1.22c-.19-.36-.91-.83-1.06-.44A22.12,22.12,0,0,0,7.51,19c0,1.42-.06,2.79-.08,4.13a15.58,15.58,0,0,1,5.9-.13l9.18,1a121.92,121.92,0,0,0-.79-16C21.65,7.35,21.46,6.59,20.86,6.47Z"
        />
        <path
          className="square square--secondary"
          d="M100.17,95.72a3.81,3.81,0,0,0,2.32-2c.79-1.85-.18-3.89-.72-5.83-.1-.36-.18-.72-.25-1.08l-.09,0c-1.24-.18-2.6.57-3.69,0-.45-.25-.75-.69-1.17-1a4,4,0,0,0-1.6-.53,47,47,0,0,0-11.79-.49l-1.27.11a1.92,1.92,0,0,1,0,.24c-.24,3-.21,6.23-1.12,8.94a1.82,1.82,0,0,0,0,1.62,1.47,1.47,0,0,0,.5.39A13.35,13.35,0,0,0,86,97c3.31-.06,5.66-.25,9.17-.49A24.77,24.77,0,0,0,100.17,95.72Z"
        />
        <path
          className="square"
          d="M105.6,91.24c-.39-2-2.12-4.1-4.1-4.43.07.36.15.72.25,1.08.54,1.94,1.52,4,.72,5.83a3.79,3.79,0,0,1-2.31,2,19.23,19.23,0,0,0,4.57-1.9,2,2,0,0,0,.91-1.13A2.86,2.86,0,0,0,105.6,91.24Z"
        />
      </g>
      <g>
        <path className="letter" d="M133.82,41.12h6.06V60.69H150v5.2H133.82Z" />
        <path className="letter" d="M163.71,41.12h6.06V65.89h-6.06Z" />
        <path
          className="letter"
          d="M186,41.12h15.52v5.2H192v4.5h7.56V56H192v4.68H202v5.2H186Z"
        />
        <path
          className="letter"
          d="M218.07,58.16s2.81,2.57,5.93,2.57c1.24,0,2.6-.49,2.6-2,0-2.94-11.16-2.84-11.16-10.46,0-4.57,3.85-7.59,8.77-7.59,5.33,0,8,2.81,8,2.81l-2.56,5a9.48,9.48,0,0,0-5.47-2.18c-1.25,0-2.64.56-2.64,1.94,0,3.12,11.16,2.57,11.16,10.39,0,4.12-3.16,7.69-8.73,7.69a12.79,12.79,0,0,1-9-3.53Z"
        />
        <path
          className="letter"
          d="M247.19,41.12h15.52v5.2h-9.46v4.5h7.56V56h-7.56v4.68h9.95v5.2h-16Z"
        />
        <path
          className="letter"
          d="M277.94,41.12h8.63a12.67,12.67,0,0,1,4.81.59c2.81,1.07,4.58,3.6,4.58,7.2a7.54,7.54,0,0,1-3.71,6.76v.07a13.74,13.74,0,0,1,.94,1.49L298,65.89h-6.76l-4.39-8.35H284v8.35h-6.07Zm8.7,11.22a2.83,2.83,0,0,0,3.15-3c0-1.87-.69-3-3.74-3h-2v6Z"
        />
      </g>
    </SvgStyled>
  );
}
