import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'emotion-theming';
import { Helmet } from 'react-helmet';

import { styled } from 'utils/emotion';

import Footer from 'components/Footer';
import Header from 'components/Header';
// import CookieInfo from 'components/CookieInfo';

import themeDefault from './themeDefault';
import './globalStyles';

const PageContainer = styled('div')(
  {
    position: 'relative',
    minHeight: '100vh',
  },
  ({ theme }) => ({
    backgroundColor: theme.pagesDefaultBackground,
  }),
);

export default class Wrapper extends Component {
  componentWillReceiveProps() {
    if (typeof window !== 'undefined') {
      window.previousLocation = this.props.location;
    }
  }

  render() {
    const { children } = this.props;

    return (
      <ThemeProvider theme={themeDefault}>
        <>
          <Helmet>
            <link
              rel="apple-touch-icon"
              sizes="180x180"
              href="/apple-touch-icon.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="32x32"
              href="/favicon-32x32.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="16x16"
              href="/favicon-16x16.png"
            />
            <link rel="manifest" href="/site.webmanifest" />
            <link
              rel="mask-icon"
              href="/safari-pinned-tab.svg"
              color="#2a6afa"
            />
            <meta name="msapplication-TileColor" content="#ffffff" />
            <meta name="theme-color" content="#ffffff" />
          </Helmet>
          <Header />
          <PageContainer>{children}</PageContainer>
          <Footer />
          {/* <CookieInfo /> */}
        </>
      </ThemeProvider>
    );
  }
}

Wrapper.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
};
