import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import theme from 'components/Layout/themeDefault';
import HeaderDesktop from './HeaderDesktop';
import HeaderMobile from './HeaderMobile';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: false,
      isDesktop: false,
      mobileMenuOpen: false,
    };
  }

  componentWillMount() {
    if (typeof window !== 'undefined') {
      this.setMobileState();
      window.addEventListener('resize', this.handleWindowSizeChange);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setMobileState();
    if (!this.state.isMobile) this.closeMobileMenu();
  };

  setMobileState() {
    if (window.innerWidth < theme.breakpoints.lg) {
      this.setState({
        isMobile: true,
        isDesktop: false,
      });
    } else {
      this.setState({
        isMobile: false,
        isDesktop: true,
      });
    }
  }

  openMobileMenu = () => {
    this.setState({ mobileMenuOpen: true });
    document.body.classList.add('no-scroll');
  };

  closeMobileMenu = () => {
    this.setState({ mobileMenuOpen: false });
    document.body.classList.remove('no-scroll');
  };

  handleMobileMenuTriggerClick = () => {
    this.state.mobileMenuOpen ? this.closeMobileMenu() : this.openMobileMenu();
  };

  render() {
    const { isMobile, isDesktop, mobileMenuOpen } = this.state;

    if (isMobile)
      return (
        <HeaderMobile
          {...this.props.data.craft}
          mobileMenuOpen={mobileMenuOpen}
          closeMobileMenu={this.closeMobileMenu}
          mobileMenuTrigger={this.handleMobileMenuTriggerClick}
        />
      );

    if (isDesktop) return <HeaderDesktop {...this.props.data.craft} />;

    return null;
  }
}

export default () => (
  <StaticQuery
    query={graphql`
      query HeaderQuery {
        craft {
          globals {
            generalContent {
              emailAddress
              phoneNumber
              postalCode
              city
              address1
              address2
            }
          }

          entries(pageInMenu: true) {
            ... on Craft_Pages {
              id
              slug
              title
              uri
              level
              pageInMenu
              children(pageInMenu: true) {
                id
                slug
                title
                uri
                level
              }
            }
          }
        }
      }
    `}
    render={data => <Header data={data} />}
  />
);
