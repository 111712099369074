export const onClientEntry = () => {
  var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
};

export const shouldUpdateScroll = () => true;

export const onRouteUpdate = ({ location }) => {
  // remove "overflow: hidden" when changing the page over a menu link
  document.body.classList.remove('no-scroll');
};
