const theme = require('./components/Layout/themeDefault');

module.exports = {
  siteMetadata: {
    siteTitle: 'LIESER Rechtsanwälte',
    siteUrl: 'https://www.lieser-rechtsanwaelte.de', // no trailing slash (used to create xml sitemap)
    title: 'LIESER Rechtsanwälte',
    titleTemplate: '%s - LIESER Rechtsanwälte',
  },
  webfontconfig: {
    google: {
      families: [`${theme.font.primaryFamily}:${theme.font.primaryWeights}`],
    },
  },
  responsive: {
    breakpoints: {
      xxs: theme.breakpoints.xxs,
      xs: theme.breakpoints.xs,
      sm: theme.breakpoints.sm,
      md: theme.breakpoints.md,
      lg: theme.breakpoints.lg,
      xl: theme.breakpoints.xl,
    },
    wrapSizes: {
      xxs: theme.wrapSizes.xxs,
      xs: theme.wrapSizes.xs,
      sm: theme.wrapSizes.sm,
      md: theme.wrapSizes.md,
      lg: theme.wrapSizes.lg,
      xl: theme.wrapSizes.xl,
    },
  },
};
