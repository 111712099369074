import React from 'react';
import { styled } from 'utils/emotion';
import media from 'utils/mediaqueries';

const SectionDefault = styled('section')(
  {
    position: 'relative',
    boxSizing: 'border-box',
  },
  ({ theme }) => ({
    padding: `${theme.grid.sectionPaddingYSmall} ${theme.grid.sectionPaddingX}`,
    [media(640)]: {
      padding: `${theme.grid.sectionPaddingYSmall} ${
        theme.grid.sectionPaddingXExtended
      }`,
    },
    [media(960)]: {
      padding: `${theme.grid.sectionPaddingY} ${
        theme.grid.sectionPaddingXExtended
      }`,
    },
    [media('lg')]: {
      padding: `${theme.grid.sectionPaddingY} ${theme.grid.sectionPaddingX}`,
    },
  }),
);

export default function Section({ children, ...rest }) {
  return <SectionDefault {...rest}>{children}</SectionDefault>;
}
