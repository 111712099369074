import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'emotion-theming';

import { styled } from 'utils/emotion';
import media from 'utils/mediaqueries';

import IconMail from 'components/Svg/IconMail';
import IconMarker from 'components/Svg/IconMarker';
import IconTel from 'components/Svg/IconTel';

const WrapperStyled = styled('nav')(
  {
    paddingTop: 20,
    lineHeight: '1.5',
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    svg: {
      marginRight: 10,
      flex: 'none',

      [media('md')]: {
        marginRight: 15,
      },
    },
  },
  props => ({
    alignItems: props.isSimple ? 'center' : 'flex-start',

    [media('sm')]: {
      alignItems: props.isSimple ? 'center' : 'flex-end',
    },

    [media('lg')]: {
      alignItems: 'flex-end',
    },
  }),
);

const RowStyled = styled('div')(
  {
    flex: 'none',
    display: 'flex',
    flexDirection: 'column',
  },
  props => ({
    alignItems: props.isSimple ? 'center' : 'flex-start',

    [media('sm')]: {
      flexDirection: props.isSimple ? 'column' : 'row',
      flexWrap: props.isSimple ? 'wrap' : 'nowrap',
      alignItems: props.isSimple ? 'center' : 'flex-end',
    },
    [media('lg')]: {
      flexDirection: 'row',
      flexWrap: 'nowrap',
    },
  }),
);

const ItemStyled = styled('div')(
  {
    flex: 'none',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    lineHeight: '1.5',

    '&:first-child': {
      marginLeft: 0,
    },
  },
  props => ({
    padding: props.hasAddress ? '5px 0' : '0',

    [media('sm')]: {
      marginLeft: props.isSimple ? 0 : 25,
    },
    [media('lg')]: {
      marginLeft: 25,
    },
  }),
);

const LinkStyled = styled('a')(
  {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'normal',
    fontSize: 14,
    textDecoration: 'none',

    '&:hover, &:focus, &:active': {
      [media('lg')]: {
        textDecoration: 'underline',
      },
    },
  },
  props => ({
    color: props.theme.primaryColor ? props.theme.primaryColor : '#2A6AFA',
  }),
);

class ContactNav extends Component {
  render() {
    const {
      globals,
      isMobile,
      theme,
      hasAddress,
      alignItems,
      isSimple,
      className,
    } = this.props;

    return (
      <WrapperStyled
        alignItems={alignItems}
        isSimple={isSimple}
        className={className}
      >
        <RowStyled hasAddress={hasAddress} isSimple={isSimple}>
          <ItemStyled hasAddress={hasAddress} isSimple={isSimple}>
            <LinkStyled href={`mailto:${globals.emailAddress}`}>
              <IconMail color={isMobile ? theme.primaryColor : null} />
              {globals.emailAddress}
            </LinkStyled>
          </ItemStyled>
          <ItemStyled hasAddress={hasAddress} isSimple={isSimple}>
            <LinkStyled href={`tel:${globals.phoneNumber.replace(/\s/g, '')}`}>
              <IconTel color={isMobile ? theme.primaryColor : null} />
              {globals.phoneNumber}
            </LinkStyled>
          </ItemStyled>
        </RowStyled>
        {hasAddress && (
          <RowStyled hasAddress={hasAddress} isSimple={isSimple}>
            <ItemStyled hasAddress={hasAddress} isSimple={isSimple}>
              <IconMarker color={isMobile ? theme.primaryColor : null} />
              <span>
                {globals.address1}, {globals.postalCode} {globals.city}
              </span>
            </ItemStyled>
          </RowStyled>
        )}
      </WrapperStyled>
    );
  }
}

ContactNav.propTypes = {
  globals: PropTypes.object,
  isMobile: PropTypes.bool,
  isSimple: PropTypes.bool,
  hasAddress: PropTypes.bool,
};

ContactNav.defaultProps = {
  globals: {},
  isMobile: false,
  isSimple: true,
  hasAddress: false,
};

export default withTheme(ContactNav);
