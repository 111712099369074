import React from 'react';
import { styled } from 'utils/emotion';

const StyledContainer = styled('div')(
  {
    width: '100%',
    margin: '0 auto',
  },
  props => ({
    maxWidth: props.theme.grid.containerMaxWidth,
    position: props.position,
    display: props.display,
  }),
);

export default function Container(props) {
  const { children, position, display } = props;
  return (
    <StyledContainer position={position} display={display} {...props}>
      {children}
    </StyledContainer>
  );
}
