module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-etracker-analytics/gatsby-browser'),
      options: {"plugins":[],"secureCode":"irKl6m"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-layout/gatsby-browser'),
      options: {"plugins":[],"component":"/opt/build/repo/src/components/Layout/Layout.js"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-emotion/gatsby-browser'),
      options: {"plugins":[],"autoLabel":true},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-web-font-loader/gatsby-browser'),
      options: {"plugins":[],"google":{"families":["Work Sans:400,500,600,700"]}},
    },{
      plugin: require('/opt/build/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
