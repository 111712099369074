import React, { Component } from 'react';
import Link from 'components/Link';
import Sticky from 'react-stickynode';

import { css, styled } from 'utils/emotion';

import Container from 'components/Container';
import Logo from 'components/Logo';
import PagesMenu from 'components/Menu/PagesMenu';
import ContactNav from 'components/ContactNav';

const Header = styled('header')({
  position: 'relative',
  zIndex: 2,
});

const headerContainer = css({
  display: 'flex',
  alignItems: 'center',
});

const MainNav = styled('div')(
  {
    display: 'flex',
    padding: '0px 10px',
    height: '100px',
    transition: 'background .25s ease',
  },
  ({ theme }) => ({
    '.logo': {
      width: 160,
      height: 53,
      transition: theme.transitionDefault,
      path: {
        // box
        '&.square': {
          fill: '#0069e7',
          opacity: 1,
        },
        '&.square--secondary': {
          fill: '#002aa0',
          opacity: 1,
        },
        // letters
        '&.letter': {
          fill: '#1d1d1b',
        },
      },
    },
    '&.sticky': {
      background: theme.primaryColorVariant,
    },
    '&.sticky .logo': {
      marginTop: 1,
      width: 130,
      height: 43,
      path: {
        // box
        '&.square': {
          fill: '#ffffff',
          opacity: 0.6,
        },
        '&.square--secondary': {
          fill: '#ffffff',
          opacity: 1,
        },
        // letters
        '&.letter': {
          fill: '#ffffff',
        },
      },
    },
    '&.sticky a.nav-link': {
      color: 'white',
    },
    '&.sticky a.active': {
      color: 'white',
    },
    'a:hover': {
      textDecoration: 'underline',
    },
  }),
);

const ContactLinkStyled = styled(Link)(
  {
    fontSize: 14,
    fontWeight: '500',
    textDecoration: 'none',
    backgroundColor: '#ffffff',
    padding: '12px 20px',
  },
  props => ({
    color: props.theme.primaryColor,
    borderRadius: props.theme.borderRadius,

    '&:hover, &:active, &:focus': {
      color: props.theme.primaryColor,
    },
  }),
  props =>
    props.issticky === 'sticky' && {
      color: props.theme.primaryColorDarker,
      backgroundColor: '#ffffff',

      '&:hover, &:active, &:focus': {
        color: props.theme.primaryColorDarker,
      },
    },
);

class HeaderDesktop extends Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   searchMenuOpen: false,
    // };
    this.inputSearch = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props.path !== prevProps.path) {
      this.closeSearchMenu();
    }
  }

  // openSearchMenu = () => {
  //   this.setState({ searchMenuOpen: true });
  // };

  // closeSearchMenu = () => {
  //   this.setState({ searchMenuOpen: false });
  // };

  render() {
    const { entries, globals } = this.props;
    // console.log(globals);
    return (
      <Header id="header">
        <Container style={{ paddingRight: '20px' }}>
          <ContactNav globals={globals.generalContent} />
        </Container>
        <Sticky enabled top={0}>
          {stickyState => {
            const isSticky = stickyState.status === Sticky.STATUS_FIXED;
            return (
              <MainNav className={isSticky ? 'sticky' : null}>
                <Container className={headerContainer}>
                  <Link to="/">
                    <Logo />
                  </Link>
                  <PagesMenu
                    entries={entries}
                    openSearchClick={this.openSearchMenu}
                  />
                  {/* {this.state.searchMenuOpen ? (
                    <SearchMenu
                    isSticky={isSticky}
                      closeSearchClick={this.closeSearchMenu}
                    />
                  ) : (
                    <PagesMenu
                      entries={entries}
                      openSearchClick={this.openSearchMenu}
                    />
                  )} */}
                  <ContactLinkStyled
                    to="/#kontakt"
                    issticky={isSticky ? 'sticky' : undefined}
                  >
                    Kontakt
                  </ContactLinkStyled>
                </Container>
              </MainNav>
            );
          }}
        </Sticky>
      </Header>
    );
  }
}

export default HeaderDesktop;
