import React, { Component } from 'react';
import Link from 'components/Link';
import { withTheme } from 'emotion-theming';

import { css } from 'utils/emotion';

const subMenuWrap = css({
  position: 'absolute',
  left: -10,
  right: '0',
  top: '100%',
  // transform: 'translateX(-50%)',
  width: 240,
  background: 'transparent',
  paddingTop: '18px',
  transition: 'none',
  visibility: 'hidden',
  opacity: 0,
});

const getSubMenuStyle = theme =>
  css({
    background: 'white',
    width: '100%',
    padding: '10px 20px 8px',
    position: 'relative',
    borderRadius: 0,
    '> a': {
      color: theme.primaryColorDark,
      display: 'block',
      borderBottom: `1px solid ${theme.primaryColorLightBorder}`,
      padding: '10px 10px 12px',
    },
    '> a:hover, > a:active, > a:focus': {
      color: theme.primaryColorDark,
      textDecoration: 'underline',
    },
    '> a:last-child': {
      borderBottom: 'none',
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      transform: 'translateX(-50%)',
      left: 40,
      top: -7,
      width: 0,
      height: 0,
      borderLeft: '7px solid transparent',
      borderRight: '7px solid transparent',
      borderBottom: '7px solid white',
      margin: '0 0 15px 0',
    },
    boxShadow: '0 3px 6px 0 rgba(0,0,0,.15)',
  });

class SubMenu extends Component {
  render() {
    const { entries, mobileMenuOpen, theme } = this.props;
    return (
      <div className={mobileMenuOpen ? '' : subMenuWrap}>
        <div className={mobileMenuOpen ? '' : getSubMenuStyle(theme)}>
          {entries.map(entry => (
            <Link
              to={`/${entry.uri}`}
              key={entry.id}
              onClick={this.props.subMenuLinkClick}
            >
              {entry.title}
            </Link>
          ))}
        </div>
      </div>
    );
  }
}

export default withTheme(SubMenu);
