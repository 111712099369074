const primaryColor = '#2A6AFA';
const primaryColorDark = '#01289E';
const primaryColorDarker = '#0A1D46';
const primaryColorDarkest = '#091736';
const primaryColorLightBorder = '#EBF1FF';
const primaryColorVariant = '#1343C4';
const transitionDefault = 'all .2s ease';

module.exports = {
  wrapSizes: {
    xxs: '100%',
    xs: '100%',
    sm: '100%',
    md: '100%',
    lg: '100%',
    xl: '100%',
  },
  breakpoints: {
    xxs: 375,
    xs: 480,
    sm: 768,
    md: 1024,
    lg: 1200,
    xl: 1360,
  },
  headlines: {
    h1: {
      mobile: 28,
      desktop: 46,
    },
    h2: {
      mobile: 24,
      desktop: 34,
    },
    h3: {
      mobile: 24,
      desktop: 34,
    },
    h4: {
      mobile: 24,
      desktop: 28,
    },
    h5: {
      mobile: 18,
      desktop: 14,
    },
  },
  grid: {
    pageMinWidth: '330px',
    containerMaxWidth: '1130px',
    sectionPaddingX: '20px',
    sectionPaddingY: '100px',
    sectionPaddingYSmall: '70px',
    sectionPaddingXExtended: '40px', // use between 640 -> 1200
  },
  font: {
    primaryFamily: 'Work Sans',
    primaryWeights: '400,500,600,700',
  },
  button: {
    fontSize: 14,
    fontWeight: '600',
    padding: '20px 40px',
    paddingSm: '13px 40px',
    primaryTextColor: 'white',
    primaryBgColor: primaryColor,
    secondaryTextColor: primaryColorDarker,
    secondaryBgColor: 'white',
    tertiaryTextColor: 'white',
    tertiaryBgColor: 'transparent',
    transition: transitionDefault,
  },
  primaryColor,
  primaryColorDark,
  primaryColorDarker,
  primaryColorDarkest,
  primaryColorLightBorder,
  primaryColorVariant,
  pagesDefaultBackground: primaryColorVariant,
  textColor: '#000',
  textColorThemeDark: 'white',
  textColorThemeLight: 'black',
  linkColor: primaryColor,
  linkColorHover: primaryColor,
  borderRadius: '5',
  borderColor: 'white',
  bgGradientBlueFrom: primaryColor,
  bgGradientBlueTo: '#1446B5',
  bgGradientBlueLightFrom: '#8DC9FF',
  bgGradientBlueLightTo: '#A2BFFD',
  bgGradientBlueOverlayFrom: 'rgba(19, 67, 196, 0.77)',
  bgGradientBlueOverlayTransparent: 'rgba(19, 67, 196, 0.09)',
  bgGradientBlueOverlayTo: 'rgba(2, 25, 121, 0.77)',
  bgGradientBlackOverlayFrom: 'rgba(47, 47, 47, 0.7)',
  bgGradientBlackOverlayTo: 'rgba(0, 0, 0, 0.7)',
  inputSmPadding: '10px 50px 13px 20px',
  scrollBarThumb: '#eaeaea',
  scrollBarTrack: '#f9f9f9',
  scrollBarWidth: '10px',
  transitionDefault,
  colorSuccess: '#24AA62',
  colorError: '#EF4C4C',
};
