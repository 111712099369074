import React from 'react';
import { styled } from 'utils/emotion';

// import IconSearch from 'components/Svg/IconSearch';

import Menu from './Menu';

const PagesMenuContainer = styled('div')({
  display: 'flex',
  marginLeft: 'auto',
});

// const ButtonSearch = styled('button')({
//   outline: 'none',
//   cursor: 'pointer',
//   margin: '3px 55px 0 0',
// });

export default function SearchMenu({
  entries,
  // openSearchClick
}) {
  return (
    <PagesMenuContainer className="pageMenu">
      <Menu entries={entries} showSubMenuEntries />
      {/* rs: commented out for mvp */}
      {/* <ButtonSearch onClick={props.openSearchClick}>
        <IconSearch />
      </ButtonSearch> */}
    </PagesMenuContainer>
  );
}
