import React from 'react';
import { styled } from 'utils/emotion';

const SvgStyled = styled('svg')(
  {
    width: '1em',
    height: '1em',
  },
  props => ({
    fill: props.color ? props.color : 'currentColor',
  }),
);

const IconArrowUp = props => {
  const { className } = props;

  return (
    <SvgStyled
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 10 6.175"
      preserveAspectRatio="xMidYMid meet"
      className={className}
    >
      <path
        d="M11,8,6,13l1.175,1.175L11,10.358l3.825,3.817L16,13Z"
        transform="translate(-6 -8)"
      />
    </SvgStyled>
  );
};

export default IconArrowUp;
