import React, { Component } from 'react';
import Link from 'components/Link';

import media from 'utils/mediaqueries';
import { styled } from 'utils/emotion';

import SubMenu from './SubMenu';

const MenuWrapper = styled('nav')({
  position: 'relative',
  marginLeft: 'auto',
  marginTop: 1,

  a: {
    fontSize: 14,
    fontWeight: '500',
    textDecoration: 'none',
  },
});

const MenuItem = styled('div')(
  {
    position: 'relative',
    lineHeight: '1',
    [media('lg')]: {
      display: 'inline-block',
      margin: '30px 20px',
      padding: '0 10px',
    },
    'a.active': {
      fontWeight: '600',
    },
  },
  ({ theme, isSticky }) => ({
    '> a:hover + div, > a + div:hover': {
      [media('lg')]: {
        display: 'block',
        visibility: 'visible',
        opacity: 1,
        transition: theme.transitionDefault,
      },
    },
  }),
);

const isActive = ({ isPartiallyCurrent }) =>
  isPartiallyCurrent ? { className: 'active' } : null;

export default class Menu extends Component {
  render() {
    const {
      entries,
      mobileMenuOpen,
      showSubMenuEntries,
      menuEntryClick,
    } = this.props;

    return (
      <MenuWrapper>
        <MenuItem onClick={menuEntryClick}>
          <Link className="nav-link" to="/">
            Startseite
          </Link>
        </MenuItem>

        {entries.map(entry => {
          let subMenuEntries;
          if (entry.children && entry.children.length)
            subMenuEntries = entry.children;
          if (entry.level === 1)
            return (
              <MenuItem key={entry.id + 1} onClick={menuEntryClick}>
                <Link
                  className="nav-link"
                  to={`/${entry.uri}`}
                  getProps={isActive}
                >
                  {entry.title}
                </Link>
                {showSubMenuEntries && subMenuEntries && (
                  <SubMenu
                    entries={subMenuEntries}
                    mobileMenuOpen={mobileMenuOpen}
                  />
                )}
              </MenuItem>
            );
        })}
      </MenuWrapper>
    );
  }
}
