import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { styled } from 'utils/emotion';
import media from 'utils/mediaqueries';
import Section from 'components/Section';
import Container from 'components/Container';
import Logo from 'components/Logo';
import ContactNav from 'components/ContactNav';
import { bgGradientBlue } from 'components/Gradients';
import FooterLocations from './FooterLocations';
import FooterLegal from './FooterLegal';

const FooterStyled = styled('footer')({
  position: 'relative',
  boxSizing: 'border-box',
  width: '100%',
  color: '#fff',
  fontSize: 14,
});

const InfoRow = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  boxSizing: 'border-box',
  alignItems: 'flex-start',

  [media('sm')]: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },

  [media('lg')]: {
    alignItems: 'center',
  },
});

const LogoStyled = styled(Logo)({
  flex: 'none',
  width: 150,
  height: 50,
  margin: '0 0 40px',

  [media('sm')]: {
    marginBottom: 0,
  },

  '.letter': {
    fill: '#fff',
    opacity: 1,
  },

  '.square': {
    fill: '#fff',
    opacity: 0.6,
  },

  '.square--secondary': {
    opacity: 1,
  },
});

const ContactNavStyled = styled(ContactNav)({
  flex: 'none',
  padding: '0',
  a: {
    color: 'white',
  },
  'a:hover, a:focus, a:active': {
    color: 'white',
  },
  [media('md')]: {
    padding: '0',
  },
});

const Divider = styled('hr')({
  height: '1px',
  width: '100%',
  border: 0,
  backgroundColor: '#fff',
  margin: '50px 0',
});

const Footer = props => {
  const {
    data: {
      craft: {
        globals: {
          general,
          footer: { copyrightText, navigationItems },
        },
        entries,
      },
    },
  } = props;

  return (
    <FooterStyled>
      <Section className={bgGradientBlue}>
        <Container>
          <InfoRow>
            <LogoStyled themeName="white" />
            <ContactNavStyled globals={general} isSimple={false} hasAddress />
          </InfoRow>
          <Divider />
          <FooterLocations items={entries} />
          <Divider />
          <FooterLegal
            copyrightText={copyrightText}
            navigationItems={navigationItems}
          />
        </Container>
      </Section>
    </FooterStyled>
  );
};

export default () => (
  <StaticQuery
    query={graphql`
      query FooterQuery {
        craft {
          globals {
            general: generalContent {
              emailAddress
              phoneNumber
              postalCode
              city
              address1
              address2
            }

            footer {
              copyrightText: footerCopyright
              navigationItems: footerNavigation {
                id
                title
                fullUri
              }
            }
          }

          entries(section: locations, orderBy: "title ASC") {
            ... on Craft_Locations {
              id
              title
              slug
              locationAddress
              locationZip
              locationCity
              locationPhone
              locationFax
              locationEmail
              locationType {
                id
                slug
                title
                locationTypeId
              }
            }
          }
        }
      }
    `}
    render={data => <Footer data={data} />}
  />
);
