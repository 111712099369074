// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-news-js": () => import("/opt/build/repo/src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-jobs-js": () => import("/opt/build/repo/src/templates/jobs.js" /* webpackChunkName: "component---src-templates-jobs-js" */),
  "component---src-templates-page-404-js": () => import("/opt/build/repo/src/templates/page404.js" /* webpackChunkName: "component---src-templates-page-404-js" */),
  "component---src-templates-home-js": () => import("/opt/build/repo/src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-pages-js": () => import("/opt/build/repo/src/templates/pages.js" /* webpackChunkName: "component---src-templates-pages-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

