import React from 'react';
import { styled } from 'utils/emotion';
import media from 'utils/mediaqueries';

// Locations
const LocationsRow = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  boxSizing: 'border-box',
  maxWidth: '100%',
  marginBottom: 40,

  [media('sm')]: {
    flexDirection: 'row',
    marginBottom: 80,
  },
});

const LocationsRowHeader = styled('div')(
  {
    flex: 'none',
    width: '100%',
    maxWidth: '100%',
    lineHeight: '2',
    marginBottom: 20,

    [media('sm')]: {
      width: '25%',
      maxWidth: 440,
    },

    [media('md')]: {
      width: '30%',
    },

    [media('lg')]: {
      paddingLeft: '100px',
      width: '40%',
      maxWidth: 440,
    },

    span: {
      fontWeight: 600,
      color: '#fff',

      [media('sm')]: {
        borderBottom: 0,
      },
    },
  },
  ({ theme }) => ({
    fontSize: theme.headlines.h5.mobile,

    [media('sm')]: {
      fontSize: theme.headlines.h5.desktop,
    },
  }),
);

const LocationsRowContent = styled('div')(
  {
    maxWidth: '100%',
    marginRight: 0,

    [media('sm')]: {
      marginRight: 40,
    },
  },
  props => ({
    [media('sm')]: {
      columnCount: props.columns ? 2 : 1,
      columnGap: props.columns ? 100 : 0,
    },
  }),
);

// Court location
const CourtStyled = styled('span')({
  display: 'inline-block',
  fontWeight: 600,
  marginRight: 25,
  lineHeight: '2',
});

// Office  location
const OfficeStyled = styled('p')({
  display: 'inline-block',
  width: '100%',
  color: 'inherit',
  lineHeight: '1.5',
  margin: '0 0 30px',
  pageBreakInside: 'avoid',
  breakInside: 'avoid',

  [media('sm')]: {
    margin: '0 0 50px',
  },
});

const OfficeHeaderStyled = styled('span')({
  fontWeight: 600,
  display: 'block',
  margin: '0 0 8px',
});

const OfficeAddressStyled = styled('span')({
  display: 'block',
  margin: '0 0 18px',
});

const OfficeAdditionalStyled = styled('span')({
  display: 'block',
});

const OfficeLinkStyled = styled('a')({
  color: '#fff',
  textDocoration: 'none',
  fontWeight: 'inherit',

  '&:hover': {
    color: 'inherit',
  },
});

const FooterLocations = props => {
  const { items } = props;

  const offices = items.filter(
    location => location?.locationType?.[0]?.locationTypeId === 'office',
  );

  const courts = items.filter(
    location => location?.locationType?.[0]?.locationTypeId === 'court',
  );

  return (
    <>
      <LocationsRow>
        <LocationsRowHeader>
          <span>Gerichtsstandorte</span>
        </LocationsRowHeader>
        <LocationsRowContent>
          {courts.map(item => (
            <CourtStyled key={`${item.type}-${item.slug}`}>
              {item.title}
            </CourtStyled>
          ))}
        </LocationsRowContent>
      </LocationsRow>
      <LocationsRow>
        <LocationsRowHeader>
          <span>Büros</span>
        </LocationsRowHeader>
        <LocationsRowContent columns>
          {offices.map(item => (
            <OfficeStyled key={`${item.id}-${item.slug}`}>
              <OfficeHeaderStyled>{item.title}</OfficeHeaderStyled>
              <OfficeAddressStyled>
                {item.locationAddress}
                <br />
                {item.locationZip} {item.locationCity}
              </OfficeAddressStyled>
              <OfficeAdditionalStyled>
                {item.locationPhone && (
                  <>
                    Telefon:{' '}
                    <OfficeLinkStyled href={`tel:${item.locationPhone}`}>
                      {item.locationPhone}
                    </OfficeLinkStyled>
                    <br />
                  </>
                )}
                {item.locationFax && (
                  <>
                    Telefax: {item.locationFax}
                    <br />
                  </>
                )}
                {item.locationEmail && (
                  <OfficeLinkStyled href={`mailto:${item.locationEmail}`}>
                    {item.locationEmail}
                  </OfficeLinkStyled>
                )}
              </OfficeAdditionalStyled>
            </OfficeStyled>
          ))}
        </LocationsRowContent>
      </LocationsRow>
    </>
  );
};

export default FooterLocations;
