import { css } from 'utils/emotion';
import theme from 'components/Layout/themeDefault';

export const BLUE_GRADIENT = `linear-gradient(135deg,
  ${theme.bgGradientBlueFrom} 0%,
  ${theme.bgGradientBlueTo} 100%
)`;

export const BLUE_LIGHT_GRADIENT = `linear-gradient(135deg,
  ${theme.bgGradientBlueLightFrom} 0%,
  ${theme.bgGradientBlueLightTo} 100%
)`;

export const BLACK_TRANS_GRADIENT = `linear-gradient(135deg,
  ${theme.bgGradientBlackOverlayFrom} 0%,
  ${theme.bgGradientBlackOverlayTo} 100%
)`;

export const BLUE_TRANS_GRADIENT = `radial-gradient(circle,
  ${theme.bgGradientBlueOverlayFrom} 0%,
  ${theme.bgGradientBlueOverlayTo} 120%
)`;

export const BLUE_TRANS_GRADIENT_50 = `linear-gradient(180deg,
  ${theme.bgGradientBlueOverlayTransparent} 0%,
  ${theme.bgGradientBlueOverlayTo} 75%
)`;

export const bgGradientBlue = css({
  background: BLUE_GRADIENT,
});

export const bgGradientBlue50 = css({
  background: BLUE_TRANS_GRADIENT_50,
});

export const bgGradientBlueLight = css({
  background: BLUE_LIGHT_GRADIENT,
});

export const bgGradientOverlayBlack = css({
  '&:before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    height: '100%',
    width: '120%',
    top: 0,
    bottom: 0,
    left: '-20%',
    right: 0,
    background: BLACK_TRANS_GRADIENT,
    zIndex: '-1',
  },
});

export const bgGradientBlueOverlay = css({
  '&:before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    height: '100%',
    width: '120%',
    top: 0,
    bottom: 0,
    left: '-20%',
    right: 0,
    background: BLUE_TRANS_GRADIENT,
    zIndex: '-1',
  },
});

export const bgGradientBlueOverlay50 = css({
  '&:before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    height: '100%',
    width: '120%',
    top: 0,
    bottom: 0,
    left: '-20%',
    right: 0,
    background: BLUE_TRANS_GRADIENT_50,
    zIndex: '-1',
  },
});
