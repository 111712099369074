import React from 'react';
import { styled } from 'utils/emotion';

const SvgStyled = styled('svg')(
  {
    width: '1.2em',
    height: '1.2em',
  },
  props => ({
    fill: props.color ? props.color : 'currentColor',
  }),
);

const IconMail = props => {
  const { className } = props;

  return (
    <SvgStyled
      viewBox="0 0 20 16"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={className}
    >
      <path d="M18 0H2A2 2 0 0 0 .01 2L0 14a2.006 2.006 0 0 0 2 2h16a2.006 2.006 0 0 0 2-2V2a2.006 2.006 0 0 0-2-2zm0 4l-8 5-8-5V2l8 5 8-5z" />
    </SvgStyled>
  );
};

export default IconMail;
