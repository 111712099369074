import React from 'react';
import { styled } from 'utils/emotion';

const SvgStyled = styled('svg')(
  {
    width: '1.2em',
    height: '1.2em',
  },
  props => ({
    fill: props.color ? props.color : 'currentColor',
  }),
);

const IconTel = props => {
  const { className } = props;

  return (
    <SvgStyled
      viewBox="0 0 18 18"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={className}
    >
      <path d="M3.62 7.79a15.149 15.149 0 0 0 6.59 6.59l2.2-2.2a.994.994 0 0 1 1.02-.24 11.407 11.407 0 0 0 3.57.57 1 1 0 0 1 1 1V17a1 1 0 0 1-1 1A17 17 0 0 1 0 1a1 1 0 0 1 1-1h3.5a1 1 0 0 1 1 1 11.36 11.36 0 0 0 .57 3.57 1 1 0 0 1-.25 1.02z" />
    </SvgStyled>
  );
};

export default IconTel;
