import React, { Component } from 'react';
import Link from 'components/Link';
import { withTheme } from 'emotion-theming';
import { css, styled } from 'utils/emotion';
import media from 'utils/mediaqueries';
import Menu from 'components/Menu/Menu';
import Logo from 'components/Logo';
import ContactNav from 'components/ContactNav';

const Header = styled('header')({
  position: 'relative',
  zIndex: 1,
  top: 0,
  left: 0,
  width: '100%',
  padding: '0 15px',
  backgroundColor: 'white',
  height: 74,

  [media('xs')]: {
    padding: '0 20px',
  },
});

const mobileLogo = css({
  display: 'inline-block',

  svg: {
    display: 'inline-block',
    width: 125,
    height: 41,
    margin: '15px 0',

    [media('xs')]: {
      width: 135,
      height: 45,
    },
  },
});

const MobileMenu = styled('div')(
  {
    background: 'white',
    position: 'fixed',
    top: '0px',
    bottom: '0px',
    left: '0px',
    right: '0px',
    width: '100vw',
    height: '100%',
    overflowY: 'auto',
    padding: '70px 0 100px',

    'nav a': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '16px',
      textAlign: 'center',
      padding: '15px 0',
    },

    'nav a.active': {
      fontWeight: '500',
    },

    'nav a:hover, nav a:focus': {
      textDecoration: 'none',
    },
  },
  ({ theme }) => ({
    'nav a': {
      color: theme.primaryColor,
    },
  }),
);

const MobileMenuTrigger = styled('button')({
  cursor: 'pointer',
  outline: 'none',
  position: 'absolute',
  right: 10,
  top: 10,
  zIndex: '10',
  height: 30,
  width: 30,
  padding: '10px 5px',

  [media('xs')]: {
    paddingRight: '10px',
  },
});

const MobileMenuTriggerIcon = styled('span')(
  {
    position: 'absolute',
    display: 'block',
    width: '30px',
    height: '4px',
    fontSize: '0',
    WebkitTouchCallout: 'none',
    userSelect: 'none',
    transition: 'background-color 0.3s',

    '&, &:before, &:after': {
      background: '#c0c0c0',
      borderRadius: 5,
    },

    '&:before, &:after': {
      position: 'absolute',
      left: '0',
      width: '30px',
      height: '100%',
      content: "''",
      transition: 'transform 0.3s',
    },

    '&:before': {
      transform: 'translateY(-250%)',
    },

    '&:after': {
      transform: 'translateY(250%)',
    },
  },
  props => ({
    '&, &:before, &:after': {
      backgroundColor: props.theme.primaryColor,
    },
  }),
  props =>
    props.mobileMenuOpen && {
      '&, &:before, &:after': {
        backgroundColor: 'transparent',
      },

      '&:before': {
        transform: 'translateY(0) rotate(45deg)',
      },

      '&:after': {
        transform: 'translateY(0) rotate(-45deg)',
      },

      '&:before, &:after': {
        background: props.theme.primaryColorVariant,
      },
    },
);

class HeaderMobile extends Component {
  handleMenuEntryClick = () => {
    this.props.closeMobileMenu();
  };

  render() {
    const { entries, globals, mobileMenuOpen, mobileMenuTrigger } = this.props;

    return (
      <Header id="header">
        <Link className={mobileLogo} to="/">
          <Logo />
        </Link>

        <MobileMenuTrigger onClick={mobileMenuTrigger}>
          <MobileMenuTriggerIcon mobileMenuOpen={mobileMenuOpen} />
        </MobileMenuTrigger>

        {mobileMenuOpen && (
          <MobileMenu>
            <Menu
              entries={entries}
              mobileMenuOpen={mobileMenuOpen}
              showSubMenuEntries={false}
              menuEntryClick={this.handleMenuEntryClick}
            />
            <ContactNav globals={globals.generalContent} isMobile />
          </MobileMenu>
        )}
      </Header>
    );
  }
}

export default withTheme(HeaderMobile);
