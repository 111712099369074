import React from 'react';
import { styled } from 'utils/emotion';

const SvgStyled = styled('svg')(
  {
    width: '1.2em',
    height: '1.2em',
  },
  props => ({
    fill: props.color ? props.color : 'currentColor',
  }),
);

const IconMarker = props => {
  const { className } = props;

  return (
    <SvgStyled
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 20"
      preserveAspectRatio="xMidYMid meet"
      className={className}
    >
      <path
        d="M12,2A7,7,0,0,0,5,9c0,5.25,7,13,7,13s7-7.75,7-13A7,7,0,0,0,12,2Zm0,9.5A2.5,2.5,0,1,1,14.5,9,2.5,2.5,0,0,1,12,11.5Z"
        transform="translate(-5 -2)"
      />
    </SvgStyled>
  );
};

export default IconMarker;
